import request from '@/utils/request'

export function search (data) {
  return request({
    url: '/user/search',
    method: 'post',
    data
  })
}
export function storeUser (data) {
  return request({
    url: '/user',
    method: 'post',
    data
  })
}
export function playAudio (data) {
  return request({
    url: '/download/audio',
    method: 'post',
    responseType: 'blob',
    data
  })
}
export function getPage (page, noitems) {
  return request({
    url: '/user/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function destroyUser (_id) {
  return request({
    url: '/user/' + _id,
    method: 'delete'
  })
}
export function getUser (_id) {
  return request({
    url: '/user/' + _id,
    method: 'get'
  })
}
export function updateUser (id, data) {
  return request({
    url: '/user/' + id,
    method: 'put',
    data
  })
}
export function updateUserAudio (id, data) {
  return request({
    url: '/user/upload/audio/' + id,
    method: 'post',
    data
  })
}
export function getWorkplace () {
  return request({
    url: '/workplaceid',
    method: 'get'
  })
}
export function getUnitplace () {
  return request({
    url: '/unitid',
    method: 'get'
  })
}
export function getJobid () {
  return request({
    url: '/jobid',
    method: 'get'
  })
}
export function getTeamid () {
  return request({
    url: '/teamid',
    method: 'get'
  })
}
export function exportExcel (data) {
  return request({
    url: '/user/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
